#spinner_wrapper {
	position: absolute;
	overflow: hidden;
	width: 1000px;
	height: 210px;
	top: 68%;
	left: -15%;
	margin-top: -105px;
	margin-left: -130px;
	background-color: transparent;
	animation: none;
	-webkit-animation: none;
	transform: scale(0.28);
}
@media only screen and (max-width: 1120px) {
	#spinner_wrapper {
	  display: none !important;
	}
  }
  
#spinner {
	position: absolute;
	top: 50%;
	left: 40%;
	margin-top: -105px;
	margin-left: -275px;
}

#spinner {
	stroke-dasharray: 281;
	-webkit-animation: dash 5s infinite linear forwards;
}

/*Animation*/
@-webkit-keyframes dash {
	from {
		stroke-dashoffset:814;
	}

	to {
		stroke-dashoffset:-814;
	}
}

@keyframes dash {
	from {
		stroke-dashoffset:814;
	}

	to {
		stroke-dashoffset:-814;
	}
}

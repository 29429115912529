:root { 
--indigo_600_7f:#2c6d987f; 
--white_A700_b2:#ffffffb2; 
--gray_700:#666666; 
--white_A700_d8:#ffffffd8; 
--red_900:#b42127; 
--gray_900:#081839; 
--blue_50:#eef7ff; 
--gray_100:#f4f6f9; 
--blue_51:#dfeffa; 
--indigo_600_75:#2c6d9875; 
--indigo_600_99:#2c6d9899; 
--indigo_600_100:#2b6d9899; 
--black_900:#000000; 
--black_900_19:#00000019; 
--white_A700:#ffffff; 
--red_900_e5:#b42127e5; 
--black_900_26:#00000026; 
--indigo_600:#2c6d98; 
}
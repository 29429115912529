.card-news{
height: auto !important;

box-shadow: none;
border: none !important;

}
.card-img-top
{
     height: auto;
     max-height: 300px;
     padding-top: 0px;
     border-radius: 5%;
     
}
.image-box
{
     width: 350px;
     height: 300px;
     
}
.image-box img{
     height: 100%;
}
.card-text
{
    background-color: rgba(255, 255, 255, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #000;
}
@media only screen and (min-width: 950px) {
     .card-news{
          width:21% !important;
          }
 
}

.new-card-grid{
     flex-basis: 25%;
     display: flex;
     flex-direction: column;
     gap: 10px;
     background-color: rgb(239, 232, 232);
}

@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&display=swap");
:root {
  --primary-color: #b42127;
}


.card-title  {
  color: var(--primary-color);
  text-transform: uppercase;
}

.card {
  width: 100%;
}
.card .card-image {
  width: 100%;
  height: 300px;
   ;
}

.panel {
  padding: 5%;
  box-shadow: 0px 6px 18px -8px rgba(118, 130, 183, 1);
  border-radius: 10px;
  height: 40%;
}
span.date {
  color: var(--primary-color);
  font-weight: 700;
}
.read-more {
     border: 1px solid var(--primary-color);
     padding: 10px;
     transition: all .2s;
     position: absolute;
     bottom: 15px;
}
.read-more:hover{
     background: var(--primary-color);
     color: #fff;
     margin-top: 10px;
     
}

.card a {
  text-decoration: none;
  color: var(--primary-color);
}
.breaker {
  display: inline-block;
  height: 5px;
  background: var(--primary-color);
  width: 30px;
  position: relative;
  border-radius: 4px;
}
.breaker:after {
  position: absolute;
  content: "";
  background: var(--primary-color);
  width: 30px;
  height: 5px;
  left: 150%;
  border-radius: 4px;
}
@media screen and (max-width: 786px) {
  .card {
    width: 45%;
    margin-bottom: 30px;
  }
  a.allbtn {
    width: 20%;
  }
}

@media screen and (max-width: 430px) {
  .card {
    width: 100%;
  }
  a.allbtn {
    width: 30%;
  }
}

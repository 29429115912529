.dot {
    height: 6px;
    width: 6px;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 2px !important;
    background-color: rgb(207, 23, 23);
    border-radius: 50%;
    display: inline-block;
}
.overlay{
	--gradient-color: rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) !important;
}
.corner-top-right-bevel {
    border-style: solid;
    height: 0;
    border-color: transparent transparent #b42127 transparent;
    border-width: 0 55px 131px 0;
  }
  
@font-face {
  font-family: RobotoRomanBold;
  src: url(../assets/fonts/RobotoRomanBold.ttf);
}
html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

* {
  box-sizing: border-box;
  font-family: RobotoRomanBold !important;
}
body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
  /* height: 100vh; */
}
.dhiwise-code,
.dhiwise-navigation {
  min-height: 100%;
  background-color: white;
}
.common-row {
  flex-direction: row;
  display: flex;
}
.common-column {
  flex-direction: column;
  display: flex;
}
.common-image {
  max-width: 100%;
}
.common-stack {
  position: relative;
  flex-wrap: wrap;
}
.common-list {
  display: grid;
}
.common-grid {
  display: grid;
  flex-flow: row wrap;
  min-height: auto;
}
.common-button {
  cursor: pointer;
  outline: none;
}
.common-floating-button {
  cursor: pointer;
  outline: none;
  position: fixed !important;
}
.common-pointer {
  cursor: pointer;
}
.dhiwise-navigation {
  padding: 20px;
  background-color: #232323;
}
.dhiwise-navigation h1 {
  color: white;
}
.dhiwise-navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}
.dhiwise-navigation ul {
  padding: 0;
}
.dhiwise-navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}
.dhiwise-navigation a {
  color: #132cdc;
}
table {
  border-spacing: 0;
}
.react-datepicker-popper {
  z-index: 100000 !important;
}
.drawable-icon{
  position: absolute;
  margin: auto;
  z-index: 1000;
}
.input-wrap{
  position: relative;
}
option{
  color: #000;
}
.table-wrap{
  overflow: auto;
}
input:focus{
  outline:none;
}
.ReactModal__Body--open{
  overflow: hidden;
}
  .slider-indicator:first-child {
    margin-left: 0;
}

.slider-indicator:last-child {
margin-right: 0;
}

.image {
  display: block;
  height:200px;
  overflow: hidden;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.image .inner {
  background-size: cover;
  display: block;
  height: 200px;
  transform: scale(1, 1);
  transition: transform 0.4s;
}

.image:hover .inner {
  transform: scale(1.1, 1.1);
}

.item {
  /* A flexbox inside a flexbox
     to create vertical text inside 
     a regular flex item */
  transition: transform 0.4s ease;
}


.image:hover .rotate-y { transform: rotateY(180deg); }


img{
  object-fit: cover !important;
}
a{
  text-decoration: none !important;
}
.header_titles:hover {
  color: var(--bs-link-hover-color) !important;
}
.order1{
  order: 1;
}

.order2{
  order: 2 ;
}
@media only screen and (max-width: 1120px) {
  .order1{
    order: 2;
  }
  
  .order2{
    order: 1 ;
  }
  
  
}
@media only screen and (max-width: 600px) {
  .news-details{
    width: 60%;
  }
  
  
}
@media only screen and (max-width: 1600px) {

.image .inner {
  height: 170px;
}
}
@media only screen and (max-width: 1200px) {

  .image .inner {
    height: 200px;
  }
  }





.cards {
	max-width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 2rem;
	row-gap: 2rem;
	grid-auto-flow: dense;
	align-items: start;
}

.cards,
.card {
	margin: 0 auto;
	max-width: 320px;
	height: fit-content;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	height: 480px !important;
}

.cards,
.card:hover {
	cursor: pointer;
	box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
	transition: box-shadow 0.3s, transform 0.3s;
	transform: scale(1.05);
}

.cards,
.card:hover .card-header h3 {
	transition: color 0.3s;
	color: rgb(44 109 152);
}

.cards,
.card .card-header h3 {
	color: rgb(44 109 152);
}

.cards,
.card .card-hero img {
	width: 100%;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.cards,
.card .card-header {
	padding: 8px 16px;
}

.cards,
.card .card-body {
	padding: 8px 16px;
}

.cards,
.card .card-footer {
	padding: 8px 16px 16px 16px;
	display: flex;
	gap: 8px;
}

.cards,
.card .card-footer .footer-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
}

.cards,
.card .card-footer .avatar {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}

.muted {
	color: rgb(44 109 152);
}

@media only screen and (max-width: 1100px) {
	.cards .card {
		max-width: 280px;
	}
}

@media only screen and (max-width: 920px) {
	.cards {
		margin-top: 1rem;
		margin-bottom: 2rem;
		grid-template-columns: 1fr;
	}

	.cards .card {
		max-width: 400px;
	}
}

@media only screen and (max-width: 500px) {
	.cards {
		margin-top: 1rem;
		margin-bottom: 2rem;
		grid-template-columns: 1fr;
	}

	.cards .card {
		max-width: 320px;
	}
}

.card-body p {
	color: rgb(44 109 152) !important;
}
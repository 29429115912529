.news-card-container{
  width: 100%;
  margin-top: 5%;
 justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.3%;
}

@media only screen and (max-width: 600px) {
  .news-card-container{
    margin-left: 30%;
    margin-top: 10%;
  }
  .card-and-nav{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
.card-and-nav{
  display: flex !important;
  width: 100%;
}
.news-details{
  width: 80%;
}